import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import Badge from '@mui/material/Badge';
import Keyboard from '@mui/icons-material/Keyboard';
import SdStorage from '@mui/icons-material/SdStorage';
import Usb from '@mui/icons-material/Usb';
import { useNavigate } from 'react-router';

interface DeviceProps {
	device: Types.UsbDevice;
	setMenuEl: React.Dispatch<React.SetStateAction<Element | undefined>>;
}

const Device = (props: DeviceProps) => {
	const navigate = useNavigate();
	return (
		<ListItem
			button
			onClick={() => {
				navigate(
					`/admin/device/${props.device.device_id === Types.DeviceIdsList.CU ? 'cu' : 'key'}/${
						props.device.serial
					}`
				);
				props.setMenuEl(undefined);
			}}
		>
			<ListItemIcon>
				{props.device.device_id === Types.DeviceIdsList.CU ? (
					<SdStorage fontSize="large" color="secondary" />
				) : (
					<Keyboard fontSize="large" color="primary" />
				)}
			</ListItemIcon>
			<ListItemText
				primary={props.device.serial}
				secondary={props.device.device_id === Types.DeviceIdsList.KEY ? 'KEY' : 'CU'}
			/>
		</ListItem>
	);
};

interface DeviceMenuProps {
	user: Types.User;
	classrooms: Types.ClassroomDocument[];
	socket: Types.VotiSocket;
	token: string;
}

const DeviceMenu = (props: DeviceMenuProps) => {
	const [init, setInit] = useState(false);
	const [devices, setDevices] = useState<Types.UsbDevice[]>([]);
	const [menuEl, setMenuEl] = useState<undefined | Element>(undefined);
	const menuOpen = Boolean(menuEl);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.socket && !init) {
			props.socket.emit('set_admin', { is_admin: true });
			props.socket.emit('device_reopen', {});
			props.socket.on('devices', (response: { devices: Types.UsbDevice[] }) => {
				setDevices(response.devices);
				dispatch(Reducers.voti.usbDevices.actions.get(response.devices));
			});
			setInit(true);
		}
	}, [props.socket, init, dispatch]);

	return (
		<Box mr={1}>
			<Badge badgeContent={devices.length} color="primary">
				<Button
					variant="outlined"
					color="inherit"
					onClick={event => setMenuEl(event.currentTarget)}
					size="small"
					disabled={devices.length === 0}
				>
					<Usb fontSize="small" />
				</Button>
			</Badge>
			<Menu
				id="menu-appbar"
				anchorEl={menuEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={menuOpen}
				onClose={() => setMenuEl(undefined)}
			>
				{devices.map(device => (
					<Device device={device} key={device.serial} setMenuEl={setMenuEl} />
				))}
			</Menu>
		</Box>
	);
};

export default connect((state: Types.RootState) => ({
	device: state.device,
	classrooms: state.classrooms,
	socket: state.socket,
}))(DeviceMenu);
