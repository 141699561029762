import Replay from '@mui/icons-material/Replay';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import { useEffect } from 'react';
import * as React from 'react';
import { batch, connect, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import T from '@taktik/components/T';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface StudentListItemsProps {
	user: Types.User;
	token: string;
}

const StudentListItems = (props: StudentListItemsProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	// @pusher
	// const channel = useChannel('repeatings');
	// useEvent(channel, 'refresh', (data: Types.PusherInvitesRefresh | undefined) => {
	// 	if (data && data.users.indexOf(props.user._id) > -1) {
	// 		dispatch(Reducers.voti.repeatings.actions.get(props.token));
	// 		dispatch(Reducers.voti.repeatingItems.actions.get(props.token));
	// 	}
	// });

	useEffect(() => {
		batch(() => {
			// dispatch(Reducers.voti.homeworks.actions.get(props.token));
			dispatch(Reducers.voti.examResults.actions.get({}, props.token));
			dispatch(Reducers.common.users.actions.get(props.token, {}, { page: 0, pageSize: 100 }));
			dispatch(Reducers.common.subjects.actions.get(props.token));
			// dispatch(Reducers.voti.repeatings.actions.get(props.token));
			// dispatch(Reducers.voti.repeatingItems.actions.get(props.token));
			dispatch(Reducers.voti.segments.actions.get(props.token));
		});
	}, [props.token, dispatch]);

	return (
		<List>
			{/* <ListItem
				button
				href="/s/exams"
				selected={location.pathname === '/s/exams'}
				onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
					event.preventDefault();
					navigate('/s/exams');
				}}
				id="nav-student-exams"
			>
				<ListItemIcon>
					<ContactSupport />
				</ListItemIcon>
				<ListItemText primary={<T id="navBar.examResults" />} />
			</ListItem>
			<ListItem
				button
				href="/s/homeworks"
				selected={location.pathname === '/s/homeworks'}
				onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
					event.preventDefault();
					navigate('/s/homeworks');
				}}
				id="nav-student-homeworks"
			>
				<ListItemIcon>
					<BusinessCenter />
				</ListItemIcon>
				<ListItemText primary={<T id="navBar.homeworks.title" />} />
			</ListItem> */}
			<ListItem
				button
				href="/s/repeating"
				selected={location.pathname.startsWith('/s/repeating')}
				onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
					event.preventDefault();
					navigate('/s/repeating');
				}}
			>
				<ListItemIcon>
					<Replay />
				</ListItemIcon>
				<ListItemText primary={<T id="navBar.teacher.repeating" />} />
			</ListItem>
		</List>
	);
};

export default connect((state: Types.RootState) => ({
	user: state.user,
}))(StudentListItems);
