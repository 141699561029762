import makeStyles from '@mui/styles/makeStyles';
import theme from './theme';

const drawerWidth = 240;

const useStyle = makeStyles({
	root: {
		display: 'flex',
	},
	logo: {
		flexGrow: 1,
		marginLeft: -6,
		marginTop: 6,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		paddingTop: 52,
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	contentOpen: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		width: `calc(100vw - ${drawerWidth}px)`,
	},
	appBarSpacer: {
		paddingTop: 52,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	innerHtml: {
		'& p, & div': {
			margin: 0,
			lineHeight: 1.2,
		},
	},
	timeline: {
		background: '#fff',
		zIndex: 9,
	},
	dialogBaseline: {
		'& .MuiDialog-container': {
			alignItems: 'baseline',
		},
	},
	selectBigItem: {
		fontSize: theme.typography.h4.fontSize,
	},
	selectBig: {
		fontSize: theme.typography.h4.fontSize,
		paddingTop: 24,
		paddingBottom: 0,
	},
	paperButton: {
		background: theme.palette.grey[200],
		cursor: 'pointer',
		'&:hover': {
			background: theme.palette.grey[100],
		},
	},
	scrollPaper: {
		alignItems: 'normal',
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	button: {
		fontSize: 140,
	},
	buttonText: {
		fontSize: 80,
		position: 'absolute',
		color: theme.palette.grey[100],
	},
	loaderContainer: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&.inline': {
			height: 'auto',
		},
	},
	tabs: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		minWidth: 300,
		maxWidth: 300,
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	stepperRoot: {
		background: '#fff',
		borderRadius: '4px 4px 0 0',
		borderBottom: `1px solid ${theme.palette.grey[400]}`,
		paddingBottom: theme.spacing(3),
	},
	repeatingCheckbox: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	calendarEvent: {
		backgroundColor: '#fff',
		border: `1px solid ${theme.palette.divider}`,
		color: theme.palette.text.primary,
	},
	calendarEventLabel: {
		width: theme.spacing(1),
		height: theme.spacing(1),
		marginRight: theme.spacing(0.5),
		display: 'inline-table',
		borderRadius: 4,
	},
	calendarEventWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	menuItemNested: {
		paddingLeft: theme.spacing(4),
	},
	listItemIcon: {
		marginLeft: 6,
	},
	chipId: {
		backgroundColor: '#fad176',
	},
	chipDiffRoot: {
		height: 'auto',
	},
	chipDiffBody: {
		whiteSpace: 'normal',
	},
	rteEditor: {
		padding: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		fontSize: theme.typography.h5.fontSize,
	},
	rteEditorSmall: {
		fontSize: theme.typography.body1.fontSize,
	},
	rteContainer: {
		marginTop: '0px !important',
	},
	rteToolbar: {
		position: 'absolute',
		top: -20,
		background: theme.palette.grey[100],
	},
	rtePlaceholder: {
		minHeight: theme.spacing(6),
	},
	rteCharsLeft: {
		position: 'absolute',
		top: -12,
		right: 0,
		background: theme.palette.grey[100],
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	tabAddButton: {
		opacity: 1,
	},
	tableRowDisabled: {
		opacity: 0.5,
	},
	tabPanel: {
		background: '#fff',
	},
	tabSelected: {
		background: theme.palette.grey[200],
	},
	tabWrapper: {
		alignItems: 'flex-start',
		textTransform: 'none',
	},
	dialogActions: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(2),
		justifyContent: 'space-between',
	},
	dialogActionsSingle: {
		paddingTop: 0,
		justifyContent: 'flex-end',
	},
	revisionCheckbox: {
		position: 'absolute',
		left: theme.spacing(1),
		top: theme.spacing(1),
	},
	revisionContainer: {
		position: 'relative',
		paddingLeft: theme.spacing(3),
	},
	accordionRoot: {
		border: `1px solid ${theme.palette.grey[300]}`,
		borderBottom: 'none',
	},
	accordionRootExpanded: {
		'&:first-child, &:last-child': {
			marginBottom: `${theme.spacing(2)}px !important`,
			marginTop: `${theme.spacing(2)}px !important`,
		},
	},
	accordionDetailsRoot: {
		paddingBottom: 0,
	},
	accordionSummaryContent: {
		alignItems: 'center',
	},
	accordionDetailsTable: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	questionTitle: {
		'& p': {
			display: 'inline',
		},
	},
	contentLanding: {
		flexGrow: 1,
		overflow: 'auto',
	},
	tableGrid: {
		'& th, td': {
			border: '1px solid rgba(224, 224, 224, 1)',
		},
	},
	colorPickerPopover: {
		position: 'absolute',
		zIndex: 2,
	},
	colorPickerCover: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
});

export default useStyle;
