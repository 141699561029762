import { lazy, Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import CssBaseline from '@mui/material/CssBaseline';
import './assets/fonts.css';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider, useDispatch } from 'react-redux';
import reducer from './reducers';
import Auth, { authContext } from '@taktik/common/auth';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loader from '@taktik/components/Loader';
import ErrorBoundary from '@taktik/components/ErrorBoundary';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';

import theme from './theme';

import { PusherProvider } from '@harelpls/use-pusher';
import { SnackbarProvider, useSnackbar } from 'notistack';

// import localeCs from './translations/cs/quiz.json';
// import localeSk from './translations/sk/quiz.json';
import NavBar from './components/common/NavBar';
import TranslationLoader from '@taktik/components/TranslationLoader';
import ScrollRestoration from './components/common/ScrollRestoration';
import axios from 'axios';

// Unauthorized
const HomePage = lazy(() => import('./pages/home'));
const LoginPage = lazy(() => import('./pages/login'));
const InvitePage = lazy(() => import('./pages/invite'));
const ProfilePage = lazy(() => import('./pages/profile'));
const ForgotPasswordPage = lazy(() => import('./pages/password'));
const ResetPasswordPage = lazy(() => import('./pages/resetPassword'));
const RevisionPage = lazy(() => import('./pages/revision'));

// Admin
const DashboardPage = lazy(() => import('./pages/dashboard'));
const AdminExamsPage = lazy(() => import('./pages/admin/exams'));
const QuestionTypes = lazy(() => import('./pages/admin/questionTypes'));
const TeachingPlansPage = lazy(() => import('./pages/admin/teachingPlans'));
const SegmentsPage = lazy(() => import('./pages/admin/segments'));
const QuotesPage = lazy(() => import('./pages/admin/quotes'));
const RevisionAdminPage = lazy(() => import('./pages/admin/revision'));
const DevicesPage = lazy(() => import('./pages/admin/devices'));
const DevicePage = lazy(() => import('./pages/admin/device'));
const RegistrationsPage = lazy(() => import('./pages/admin/registrations'));
const SubjectsPage = lazy(() => import('./pages/admin/subjects'));
const SchoolsPage = lazy(() => import('./pages/admin/schools'));
const UsersPage = lazy(() => import('./pages/admin/users'));
const EmailsPage = lazy(() => import('./pages/admin/emails'));

// Teacher
const StatsPage = lazy(() => import('./pages/teacher/stats'));
const ClassroomsPage = lazy(() => import('./pages/teacher/classrooms'));
const TimetablePage = lazy(() => import('./pages/teacher/timetable'));
const TeacherTeachingPlansPage = lazy(() => import('./pages/teacher/teachingPlans'));
const ClassroomPage = lazy(() => import('./pages/teacher/classroom'));
const TeacherRepeatingPage = lazy(() => import('./pages/teacher/repeating'));
const TeacherHomeworkPage = lazy(() => import('./pages/teacher/homework'));
const TeacherDevicePage = lazy(() => import('./pages/teacher/device'));
const MySubjectsPage = lazy(() => import('./pages/teacher/subjects'));
const TeacherHistoryPage = lazy(() => import('./pages/teacher/history'));
const CustomExamsPage = lazy(() => import('./pages/teacher/customExams'));
const TeacherSchoolPage = lazy(() => import('./pages/teacher/school'));

// Student
const StudentHomepage = lazy(() => import('./pages/student/homepage'));
const InviteUserPage = lazy(() => import('./pages/student/invites'));
const StudentRepeatingPage = lazy(() => import('./pages/student/repeating'));
const StudentHomeworkPage = lazy(() => import('./pages/student/homework'));
const StudentDevicePage = lazy(() => import('./pages/student/device'));

i18next.init({
	interpolation: { escapeValue: false },
	lng: 'cs',
	resources: {
		// cs: {
		// 	quiz: localeCs,
		// },
		// sk: {
		// 	quiz: localeSk,
		// },
	},
});

axios.defaults.params = {};
axios.defaults.params['project'] = 'voti';
axios.defaults.params['language'] = i18next.language;

// Pusher config
const pusherConfig = {
	clientKey: '0d7a53bab3e525777c45',
	cluster: 'eu',
};

const ExamPage = lazy(() => import('./pages/exam'));
const store = createStore(reducer, applyMiddleware(thunk));

const App = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);

	store.subscribe(() => {
		const state = store.getState();
		const snackbars: Types.Snackbar[] = state.snackbars;
		snackbars.forEach(snackbar => {
			if (!snackbar.shown) {
				enqueueSnackbar(snackbar.message, {
					variant: snackbar.type,
				});
				dispatch(Reducers.common.snackbars.actions.clear());
			}
		});
	});

	return (
		<Auth>
			<authContext.Consumer>
				{({ authenticated, token, silentAuth }) => {
					if (!authenticated && token) {
						silentAuth();
						return;
					}

					return (
						<Router>
							<ScrollRestoration />
							<TranslationLoader
								language={i18next.language as Types.Languages}
								onLoad={() => setLoading(false)}
							/>
							<Routes>
								{/* Public routes */}
								<Route
									path="/"
									element={
										<Suspense fallback={<Loader />}>
											<HomePage />
										</Suspense>
									}
								/>
								<Route
									path="/login"
									element={
										<Suspense fallback={<Loader />}>
											<LoginPage />
										</Suspense>
									}
								/>
								<Route
									path="/admin"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<DashboardPage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/admin/registrations"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<RegistrationsPage token={token} />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/invite/:inviteId"
									element={
										<Suspense fallback={<Loader />}>
											<InvitePage />
										</Suspense>
									}
								/>
								<Route
									path="/exam/:serialNumber"
									element={
										<Suspense fallback={<Loader />}>
											<ExamPage />
										</Suspense>
									}
								/>

								<Route
									path="/forgot-password"
									element={
										<Suspense fallback={<Loader />}>
											<ForgotPasswordPage />
										</Suspense>
									}
								/>
								<Route
									path="/reset-password/:userId/:hash"
									element={
										<Suspense fallback={<Loader />}>
											<ResetPasswordPage />
										</Suspense>
									}
								/>
								{[
									'/revision/:revisionId/:tab/:page',
									'/revision/:revisionId/:tab',
									'/revision/:revisionId',
								].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<Suspense fallback={<Loader />}>
												<RevisionPage />
											</Suspense>
										}
									/>
								))}

								{/* Admin routes */}
								{[
									'/admin/exams/:page/:examId/:exerciseId/:questionId',
									'/admin/exams/:page/:examId/:exerciseId',
									'/admin/exams/:page/:examId',
									'/admin/exams/:page',
									'/admin/exams',
								].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<AdminExamsPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{['/admin/question-types/:page', '/admin/question-types'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<QuestionTypes />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{[
									'/admin/teaching-plans/:page/:teachingPlanId',
									'/admin/teaching-plans/:page',
									'/admin/teaching-plans',
								].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<TeachingPlansPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{['/admin/quotes', '/admin/quote/:quoteId'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<QuotesPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{['/admin/segments', '/admin/segment/:segmentId'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<SegmentsPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								<Route
									path="/admin/devices"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<DevicesPage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/admin/subjects"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<SubjectsPage />
											</Suspense>
										</NavBar>
									}
								/>
								{[
									'/admin/schools/:page/:schoolId/:tabId',
									'/admin/schools/:page/:schoolId',
									'/admin/schools',
								].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<SchoolsPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{['/admin/users/:page/:userId', '/admin/users/:page', '/admin/users'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<UsersPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{[
									'/admin/revision/:revisionId/:tab/:page',
									'/admin/revision/:revisionId/:tab',
									'/admin/revision/:revisionId',
									'/admin/revisions',
								].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<RevisionAdminPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								<Route
									path="/admin/device/:type/:serial"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<DevicePage />
											</Suspense>
										</NavBar>
									}
								/>
								{['/admin/emails', '/admin/email/:emailId'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<EmailsPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}

								{/* Teacher routes */}
								<Route
									path="/t/history"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<TeacherHistoryPage />
											</Suspense>
										</NavBar>
									}
								/>
								{['/t/stats', '/t/stats/:tab', '/t/stats/:tab/:refId'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<StatsPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								<Route
									path="/t/classrooms"
									element={
										<NavBar page="classrooms">
											<Suspense fallback={<Loader />}>
												<ClassroomsPage />
											</Suspense>
										</NavBar>
									}
								/>
								{['/t/custom-exams', '/t/custom-exam/:customExamId'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar page="custom-exams">
												<Suspense fallback={<Loader />}>
													<CustomExamsPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{[
									'/t/classroom/:classroomTitle',
									'/t/classroom/:classroomTitle/:tabId',
									'/t/classroom/:classroomTitle/:tabId/:refId',
								].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar page="classrooms">
												<Suspense fallback={<Loader />}>
													<ClassroomPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{[
									'/t/repeating/:repeatingId',
									'/t/repeating/:repeatingId/:itemId',
									'/t/repeating/:repeatingId/:itemId/:userId',
								].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<TeacherRepeatingPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								{['/t/homework/:homeworkId', '/t/homework/:homeworkId/:userId'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<TeacherHomeworkPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								<Route
									path="/t/device/:resultId"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<TeacherDevicePage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/t/timetable"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<TimetablePage />
											</Suspense>
										</NavBar>
									}
								/>
								{['/t/teaching-plans', '/t/teaching-plan/:id'].map(path => (
									<Route
										path={path}
										key="router"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<TeacherTeachingPlansPage />
												</Suspense>
											</NavBar>
										}
									/>
								))}
								<Route
									path="/t/subjects"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<MySubjectsPage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/t/profile"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<ProfilePage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/t/invites"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<InviteUserPage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/t/school/:schoolId"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<TeacherSchoolPage />
											</Suspense>
										</NavBar>
									}
								/>

								{/* Student routes */}
								<Route
									path="/s"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<StudentHomepage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/s/profile"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<ProfilePage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/homework/:homeworkId"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<StudentHomeworkPage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/device/:resultId"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<StudentDevicePage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/repeating/:repeatingId/:itemId"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<StudentRepeatingPage />
											</Suspense>
										</NavBar>
									}
								/>
								<Route
									path="/s/invites"
									element={
										<NavBar>
											<Suspense fallback={<Loader />}>
												<InviteUserPage />
											</Suspense>
										</NavBar>
									}
								/>
							</Routes>
						</Router>
					);
				}}
			</authContext.Consumer>
		</Auth>
	);
};

const container = document.getElementById('root');
if (container) {
	const root = createRoot(container);
	root.render(
		<Provider store={store}>
			<SnackbarProvider
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				maxSnack={3}
			>
				<ThemeProvider theme={theme}>
					<PusherProvider {...pusherConfig}>
						<I18nextProvider i18n={i18next}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<CssBaseline />
								<ErrorBoundary>
									<App />
								</ErrorBoundary>
							</LocalizationProvider>
						</I18nextProvider>
					</PusherProvider>
				</ThemeProvider>
			</SnackbarProvider>
		</Provider>
	);
}
