import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { authContext } from '@taktik/common/auth';
import Types from '@taktik/common/types';
import { useNavigate } from 'react-router-dom';
import T from '@taktik/components/T';
import Cookies from 'universal-cookie';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Explore from '@mui/icons-material/Explore';
import Person from '@mui/icons-material/Person';
import Translate from '@mui/icons-material/Translate';
import VpnKey from '@mui/icons-material/VpnKey';
import i18next from 'i18next';
import { connect } from 'react-redux';

const cookie = new Cookies();

interface UserMenuProps {
	user: Types.User;
}

const UserMenu = (props: UserMenuProps) => {
	const [profileEl, setMenuEl] = useState<undefined | Element>(undefined);
	const navigate = useNavigate();
	const profileOpen = Boolean(profileEl);
	const user = props.user;
	const language = i18next.language as Types.Languages;
	const [showTranslations, setShowTranslations] = useState(false);

	useEffect(() => {
		setShowTranslations(cookie.get('taktik_translation_mode') === 'true');
	}, []);

	return (
		<div>
			<Button
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={event => setMenuEl(event.currentTarget)}
				color="inherit"
				style={{ textTransform: 'none' }}
				id="user-menu"
			>
				{`${user.firstName} ${user.lastName}`}
			</Button>
			<Menu
				id="menu-appbar"
				anchorEl={profileEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={profileOpen}
				onClose={() => setMenuEl(undefined)}
			>
				<MenuItem
					onClick={() => {
						setMenuEl(undefined);
						navigate(
							`/${
								props.user.projects?.voti?.[language]?.role === Types.CustomerRoleTypesList.teacher
									? 't'
									: 's'
							}/profile`
						);
					}}
				>
					<ListItemIcon>
						<Person />
					</ListItemIcon>
					<T id="navBar.user.profile" />
				</MenuItem>
				{user.projects?.voti?.[language]?.role === Types.CustomerRoleTypesList.teacher && (
					<MenuItem
						onClick={() => {
							setMenuEl(undefined);
							navigate('/t/subjects');
						}}
					>
						<ListItemIcon>
							<Explore />
						</ListItemIcon>
						<T id="navBar.user.subjects" />
					</MenuItem>
				)}
				{user.role === Types.RoleTypesList.admin && [
					<Divider key="menu-item-1" />,
					!showTranslations && (
						<MenuItem
							onClick={() => {
								navigate('?t');
								setShowTranslations(true);
							}}
							key="menu-item-2"
						>
							<ListItemIcon>
								<Translate />
							</ListItemIcon>
							<T id="navBar.admin.translations.on" />
						</MenuItem>
					),
					showTranslations && [
						<MenuItem
							onClick={() => {
								navigate('?no-t');
								setShowTranslations(false);
							}}
							key="menu-item-3"
						>
							<ListItemIcon>
								<Translate />
							</ListItemIcon>
							<T id="navBar.admin.translations.off" />
						</MenuItem>,
						<Divider key="menu-item-4" />,
						<Box p={2} pb={0} key="menu-item-5">
							<Typography>
								<T id="navBar.admin.translations.loginAs" />
							</Typography>
						</Box>,
						<MenuItem
							onClick={() => {
								navigate('/');
							}}
							key="menu-item-6"
						>
							<ListItemIcon>
								<VpnKey />
							</ListItemIcon>
							<T id="types.user.roles.admin" />
						</MenuItem>,
						<MenuItem
							onClick={() => {
								navigate('/s');
							}}
							key="menu-item-7"
						>
							<ListItemIcon>
								<VpnKey />
							</ListItemIcon>
							<T id="types.user.roles.student" />
						</MenuItem>,
						<MenuItem
							onClick={() => {
								navigate('/t/history');
							}}
							key="menu-item-8"
						>
							<ListItemIcon>
								<VpnKey />
							</ListItemIcon>
							<T id="types.user.roles.teacher" />
						</MenuItem>,
					],
				]}
				<Divider />
				<authContext.Consumer>
					{({ logout }) => (
						<MenuItem
							onClick={() => {
								logout();
								navigate('/login');
							}}
							id="user-logout"
						>
							<ListItemIcon>
								<ExitToApp />
							</ListItemIcon>
							<T id="navBar.user.logout" />
						</MenuItem>
					)}
				</authContext.Consumer>
			</Menu>
		</div>
	);
};

export default connect((state: Types.RootState) => ({
	user: state.user,
}))(UserMenu);
