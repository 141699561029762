import { useEffect, useState } from 'react';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Equalizer from '@mui/icons-material/Equalizer';
import Explore from '@mui/icons-material/Explore';
import Home from '@mui/icons-material/Home';
import Restore from '@mui/icons-material/Restore';
import School from '@mui/icons-material/School';
import CastConnected from '@mui/icons-material/CastConnected';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { batch, connect, useDispatch } from 'react-redux';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import { useNavigate, useLocation } from 'react-router-dom';
import Domain from '@mui/icons-material/Domain';
import useStyles from '../../../../styles';
import T from '@taktik/components/T';
import AddClassroomDialog from '../../../teacher/dialog/MyClassrooms';
import Loader from '../../Loader';
import i18next from 'i18next';

interface TeacherListItemsProps {
	classrooms: Types.ClassroomDocument[];
	schools: Types.SchoolDocument[];
	homeworks: Types.HomeworkDocument[];
	repeatings: Types.RepeatingDocument[];
	user: Types.User;
	users: Types.User[];
	token: string;
	page?: string;
	hideMenu?: boolean;
}

const TeacherListItems = (props: TeacherListItemsProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const [user, setUser] = useState<Types.User>();
	const [classrooms, setClassrooms] = useState<Types.ClassroomDocument[]>([]);
	const [school, setSchool] = useState<Types.SchoolDocument>();
	const [addClassroomDialog, setAddClassroomDialog] = useState(false);
	const [loadingClassrooms, setLoadingClassrooms] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const projectSettings = user?.projects?.interaktiv?.[i18next.language as Types.Languages];

	useEffect(() => {
		dispatch(Reducers.common.schools.actions.get(props.token, {}, { page: 0, pageSize: 50 }));
	}, []);

	useEffect(() => {
		const u = props.users.find(u => u._id === props.user._id);
		if (u) {
			setUser(u);
		}
	}, [props.user, props.users]);

	useEffect(() => {
		const newClassrooms: Types.ClassroomDocument[] = [];
		const teacherSettings = user?.teacherSettings;
		teacherSettings?.classrooms.forEach(classroomId => {
			const classroom = props.classrooms.find(c => c._id === classroomId);
			if (classroom) {
				newClassrooms.push(classroom);
			}
		});
		setClassrooms(newClassrooms);
	}, [props.classrooms, user]);

	useEffect(() => {
		if (props.schools[0]) {
			setSchool(props.schools[0]);
			if (props.classrooms.length === 0) {
				batch(() => {
					dispatch(Reducers.common.users.actions.get(props.token, {}, { page: 0, pageSize: 100 }));
					dispatch(Reducers.common.invites.actions.get({ email: props.user.email }));
					dispatch(Reducers.common.classrooms.actions.get(props.token)).then(() =>
						setLoadingClassrooms(false)
					);
					dispatch(Reducers.common.subjects.actions.get(props.token));
					dispatch(Reducers.voti.customExams.actions.get(props.token));
					// dispatch(getHomeworks(props.token));
					// dispatch(getRepeating(props.token)).then((data: Types.RepeatingDocument[]) => {
					// 	if (data && data.length > 0) {
					// 		dispatch(
					// 			getRepeatingItems(
					// 				props.token,
					// 				data.map(item => item._id)
					// 			)
					// 		);
					// 	}
					// });
					// dispatch(getTeachingPlans(props.token));
					// dispatch(getSegments(props.token));
				});
			} else {
				setLoadingClassrooms(false);
			}
		}
	}, [props.schools]);

	useEffect(() => {
		if (school && user) {
			const isAdmin = school.teachers.findIndex(t => t._id === user._id && t.isAdmin) > -1;
			setIsAdmin(isAdmin);
		} else {
			setIsAdmin(false);
		}
	}, [school, user]);

	// @pusher
	// const homeworksChannel = useChannel('homeworks');
	// useEvent(homeworksChannel, 'sent', (data: Types.PusherHomeworkSent | undefined) => {
	// 	if (data && data.homeworkId) {
	// 		if (props.homeworks.findIndex(h => h._id === data.homeworkId) > -1) {
	// 			dispatch(getHomeworks(props.token));
	// 		}
	// 	}
	// });

	// @pusher
	// const channel = useChannel('repeatings');
	// useEvent(channel, 'refresh', (data: Types.PusherInvitesRefresh | undefined) => {
	// 	if (data && data.users.indexOf(props.user._id) > -1) {
	// 		dispatch(Reducers.voti.repeatings.actions.get(props.token));
	// 		dispatch(Reducers.voti.repeatingItems.actions.get(props.token));
	// 	}
	// });

	return (
		<>
			{!props.hideMenu && (
				<List>
					{user && school && (
						<>
							{isAdmin && (
								<ListItem
									button
									href={`/t/school/${school._id}`}
									selected={location.pathname === `/t/school/${school._id}`}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/t/school/${school._id}`);
									}}
									id="nav-admin-school"
									sx={{
										whiteSpace: 'break-spaces',
									}}
								>
									<ListItemIcon>
										<Domain />
									</ListItemIcon>
									<ListItemText primary={school.title} />
								</ListItem>
							)}
							{!isAdmin && (
								<ListItem
									button
									href="/"
									selected={location.pathname === '/'}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/`);
									}}
									sx={{
										whiteSpace: 'break-spaces',
									}}
								>
									<ListItemIcon>
										<Home color={location.pathname === '/' ? 'primary' : 'action'} />
									</ListItemIcon>
									<ListItemText primary={school.title} />
								</ListItem>
							)}
						</>
					)}
					<Box py={2} component="li">
						<Divider />
					</Box>
					<ListItem>
						<ListItemText
							primary={
								<Grid container justifyContent="space-between" alignItems="center">
									<Grid item>
										<Typography variant="h5">
											<T id="navBar.teacher.classrooms" />
										</Typography>
									</Grid>
									<Grid item>
										{classrooms.length > 0 && (
											<IconButton
												onClick={() => setAddClassroomDialog(true)}
												size="small"
												id="manage-classrooms-button"
											>
												<Edit fontSize="small" color="disabled" />
											</IconButton>
										)}
									</Grid>
								</Grid>
							}
						/>
					</ListItem>
					{classrooms.map(classroom => (
						<div key={`list-item-${classroom._id}`}>
							<ListItem
								button
								href={`/t/classroom/${classroom.title}`}
								onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
									event.preventDefault();
									navigate(`/t/classroom/${classroom.title}/new-device`);
								}}
								className="nav-classroom"
							>
								<ListItemText
									primary={
										<Typography
											color={
												location.pathname.startsWith(`/t/classroom/${classroom.title}`)
													? 'primary'
													: 'textSecondary'
											}
										>
											<b>{classroom.title}</b>
										</Typography>
									}
								/>
								<ListItemSecondaryAction>
									<Typography variant="caption">
										<T id="types.counts.student" options={{ count: classroom.students.length }} plural />
									</Typography>
								</ListItemSecondaryAction>
							</ListItem>
							{location.pathname.startsWith(`/t/classroom/${classroom.title}`) && (
								<Box mb={1}>
									<ListItem
										className={classes.menuItemNested}
										button
										href={`/t/classroom/${classroom.title}/stats`}
										selected={
											location.pathname === `/t/classroom/${classroom.title}/stats` ||
											location.pathname === `/t/classroom/${classroom.title}`
										}
										onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
											event.preventDefault();
											navigate(`/t/classroom/${classroom.title}/stats`);
										}}
										dense
									>
										{/* <ListItemIcon>
										<Equalizer fontSize="small" />
									</ListItemIcon> */}
										<ListItemText
											primary={
												<Typography variant="caption">
													<T id="pages.teacher.stats.title" />
												</Typography>
											}
										/>
									</ListItem>
									<ListItem
										className={classes.menuItemNested}
										button
										href={`/t/classroom/${classroom.title}/history`}
										selected={location.pathname === `/t/classroom/${classroom.title}/history`}
										onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
											event.preventDefault();
											navigate(`/t/classroom/${classroom.title}/history`);
										}}
										dense
									>
										{/* <ListItemIcon>
										<History fontSize="small" />
									</ListItemIcon> */}
										<ListItemText
											primary={
												<Typography variant="caption">
													<T id="pages.teacher.history.title" />
												</Typography>
											}
										/>
									</ListItem>
									<ListItem
										className={classes.menuItemNested}
										button
										href={`/t/classroom/${classroom.title}`}
										selected={location.pathname === `/t/classroom/${classroom.title}/students`}
										onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
											event.preventDefault();
											navigate(`/t/classroom/${classroom.title}/students`);
										}}
										dense
									>
										{/* <ListItemIcon>
										<Group fontSize="small" />
									</ListItemIcon> */}
										<ListItemText
											primary={
												<Typography variant="caption">
													<T id="pages.classroom.students" />
												</Typography>
											}
										/>
									</ListItem>
									<ListItem
										className={classes.menuItemNested}
										button
										href={`/t/classroom/${classroom.title}/groups`}
										selected={location.pathname === `/t/classroom/${classroom.title}/groups`}
										onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
											event.preventDefault();
											navigate(`/t/classroom/${classroom.title}/groups`);
										}}
										dense
									>
										{/* <ListItemIcon>
										<Groups fontSize="small" />
									</ListItemIcon> */}
										<ListItemText
											primary={
												<Typography variant="caption">
													<T id="navBar.teacher.groups" />
												</Typography>
											}
										/>
									</ListItem>
									<ListItem
										className={classes.menuItemNested}
										button
										href={`/t/classroom/${classroom.title}/custom-exams`}
										selected={location.pathname === `/t/classroom/${classroom.title}/custom-exams`}
										onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
											event.preventDefault();
											navigate(`/t/classroom/${classroom.title}/custom-exams`);
										}}
										dense
									>
										{/* <ListItemIcon>
										<School fontSize="small" />
									</ListItemIcon> */}
										<ListItemText
											primary={
												<Typography variant="caption">
													<T id="navBar.teacher.customExams" />
												</Typography>
											}
										/>
									</ListItem>
									<ListItem className={classes.menuItemNested}>
										<Box mt={0.5} mb={1}>
											<Button
												variant="outlined"
												endIcon={<CastConnected />}
												onClick={() => navigate(`/t/classroom/${classroom.title}/new-device`)}
												size="small"
											>
												<T id="pages.teacher.device.custom.examRun" />
											</Button>
										</Box>
									</ListItem>
									{/* <ListItem
									className={classes.menuItemNested}
									button
									href={`/t/classroom/${classroom.title}/timetable`}
									selected={location.pathname === `/t/classroom/${classroom.title}/timetable`}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/t/classroom/${classroom.title}/timetable`);
									}}
									dense
								>
									<ListItemText
										primary={
											<Typography variant="caption">
												<T id="pages.classroom.timetable" />
											</Typography>
										}
									/>
								</ListItem>
								<ListItem
									className={classes.menuItemNested}
									button
									href={`/t/classroom/${classroom.title}/repeating`}
									selected={location.pathname === `/t/classroom/${classroom.title}/repeating`}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/t/classroom/${classroom.title}/repeating`);
									}}
									dense
								>
									<ListItemText
										primary={
											<Typography variant="caption">
												<T id="pages.classroom.repeating" />
											</Typography>
										}
									/>
								</ListItem>
								<ListItem
									className={classes.menuItemNested}
									button
									href={`/t/classroom/${classroom.title}/homeworks`}
									selected={location.pathname === `/t/classroom/${classroom.title}/homeworks`}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/t/classroom/${classroom.title}/homeworks`);
									}}
									dense
								>
									<ListItemText
										primary={
											<Typography variant="caption">
												<T id="pages.classroom.homeworks" />
											</Typography>
										}
									/>
								</ListItem> */}
								</Box>
							)}
						</div>
					))}
					{classrooms.length === 0 && !loadingClassrooms && (
						<ListItem>
							<ListItemText
								primary={
									<Button
										fullWidth
										variant="contained"
										color="secondary"
										startIcon={<Add />}
										disableElevation
										onClick={() => setAddClassroomDialog(true)}
										id="manage-classrooms-button"
									>
										<T id="navBar.teacher.addClassroom" />
									</Button>
								}
							/>
						</ListItem>
					)}
					{loadingClassrooms && (
						<ListItem>
							<ListItemText primary={<Loader inline />} />
						</ListItem>
					)}
					<Box py={2} component="li">
						<Divider />
					</Box>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="h5">
									<T id="navBar.teacher.exams" />
								</Typography>
							}
						/>
					</ListItem>
					<ListItem
						button
						href="/t/custom-exams"
						selected={location.pathname.startsWith('/t/custom-exam')}
						onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
							event.preventDefault();
							navigate('/t/custom-exams');
						}}
						className="nav-custom-exams"
					>
						<ListItemIcon>
							<School color={location.pathname.startsWith('/t/custom-exam') ? 'primary' : 'action'} />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.teacher.customExams" />} />
					</ListItem>
					<ListItem
						button
						href="/t/history"
						selected={location.pathname.startsWith('/t/history')}
						onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
							event.preventDefault();
							navigate('/t/history');
						}}
					>
						<ListItemIcon>
							<Restore color={location.pathname.startsWith('/t/history') ? 'primary' : 'action'} />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.teacher.examsHistory" />} />
					</ListItem>
					<ListItem
						button
						href="/t/stats"
						selected={location.pathname.startsWith('/t/stats')}
						onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
							event.preventDefault();
							navigate('/t/stats');
						}}
					>
						<ListItemIcon>
							<Equalizer color={location.pathname.startsWith('/t/stats') ? 'primary' : 'action'} />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.teacher.stats" />} />
					</ListItem>
					<Box py={2} component="li">
						<Divider />
					</Box>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="h5">
									<T id="navBar.teacher.settings" />
								</Typography>
							}
						/>
					</ListItem>
					<ListItem
						button
						href="/t/subjects"
						selected={location.pathname === '/t/subjects'}
						onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
							event.preventDefault();
							navigate('/t/subjects');
						}}
						id="nav-subjects"
					>
						<ListItemIcon>
							<Explore color={location.pathname.startsWith('/t/subjects') ? 'primary' : 'action'} />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.teacher.subjects" />} />
					</ListItem>
					{/* <ListItem
					button
					href="/t/timetable"
					selected={location.pathname === '/t/timetable'}
					onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
						event.preventDefault();
						navigate('/t/timetable');
					}}
				>
					<ListItemIcon>
						<TableChart color={location.pathname.startsWith('/t/timetable') ? 'primary' : 'action'} />
					</ListItemIcon>
					<ListItemText primary={<T id="navBar.teacher.timetable" />} />
				</ListItem>
				<ListItem
					button
					href="/t/teaching-plans"
					selected={location.pathname === '/t/teaching-plans'}
					onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
						event.preventDefault();
						navigate('/t/teaching-plans');
					}}
				>
					<ListItemIcon>
						<MenuBook />
					</ListItemIcon>
					<ListItemText primary={<T id="navBar.teacher.teachingPlans" />} />
				</ListItem> */}
				</List>
			)}
			{addClassroomDialog && user && (
				<AddClassroomDialog open onClose={() => setAddClassroomDialog(false)} />
			)}
		</>
	);
};

export default connect((state: Types.RootState) => ({
	schools: state.schools,
	homeworks: state.homeworks,
	repeatings: state.repeatings,
	users: state.users,
	user: state.user,
}))(TeacherListItems);
