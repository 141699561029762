import { combineReducers } from 'redux';
import Reducers from '@taktik/common/reducers';

const rootReducer = combineReducers({
	books: Reducers.interaktiv.books.reducer,
	user: Reducers.common.user.reducer,
	users: Reducers.common.users.reducer,
	schools: Reducers.common.schools.reducer,
	classrooms: Reducers.common.classrooms.reducer,
	subjects: Reducers.common.subjects.reducer,
	invites: Reducers.common.invites.reducer,
	translations: Reducers.common.translations.reducer,
	translationEdit: Reducers.common.translationEdit.reducer,
	snackbars: Reducers.common.snackbars.reducer,
	notifications: Reducers.common.notifications.reducer,
	emailTemplates: Reducers.common.emailTemplates.reducer,
	exams: Reducers.voti.exams.reducer,
	exercises: Reducers.voti.exercises.reducer,
	questions: Reducers.voti.questions.reducer,
	questionTypes: Reducers.voti.questionTypes.reducer,
	questionReports: Reducers.voti.questionReports.reducer,
	examResults: Reducers.voti.examResults.reducer,
	teachingPlans: Reducers.voti.teachingPlans.reducer,
	devices: Reducers.voti.devices.reducer,
	device: Reducers.voti.device.reducer,
	tags: Reducers.voti.tags.reducer,
	elastic: Reducers.voti.elastic.reducer,
	homeworks: Reducers.voti.homeworks.reducer,
	repeatings: Reducers.voti.repeatings.reducer,
	repeatingItems: Reducers.voti.repeatingItems.reducer,
	segments: Reducers.voti.segments.reducer,
	socket: Reducers.voti.socket.reducer,
	revisions: Reducers.voti.revisions.reducer,
	revisionItems: Reducers.voti.revisionItems.reducer,
	registrations: Reducers.voti.registrations.reducer,
	guide: Reducers.voti.guide.reducer,
	stats: Reducers.voti.stats.reducer,
	history: Reducers.voti.history.reducer,
	usbDevices: Reducers.voti.usbDevices.reducer,
	customExams: Reducers.voti.customExams.reducer,
	quotes: Reducers.voti.quotes.reducer,
});

export default rootReducer;
