import { useEffect, useState } from 'react';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import { connect, useDispatch } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import T from '@taktik/components/T';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GuideSteps } from '../Guide';

interface MyClassroomsProps {
	user: Types.UserSession;
	classrooms: Types.ClassroomDocument[];
	users: Types.User[];
	isGuide?: boolean;
	onGuide?: (step: GuideSteps) => void;
}

const MyClassrooms = (props: MyClassroomsProps) => {
	const dispatch = useDispatch();
	const [user, setUser] = useState<Types.User>();
	const teacherSettings = user?.teacherSettings;
	const [loading, setLoading] = useState<string | false>(false);

	const toggleClassroom = (classroomId: string) => {
		setLoading(classroomId);
		const hasClassroom = teacherSettings?.classrooms
			? teacherSettings.classrooms.findIndex(c => c === classroomId) > -1
			: false;

		if (hasClassroom) {
			dispatch(
				Reducers.common.users.actions.removeClassroom(
					{
						userId: props.user._id,
						classroomId,
					},
					props.user.token
				)
			)
				.then(() => setLoading(false))
				.catch(() => setLoading(false));
		} else {
			dispatch(
				Reducers.common.users.actions.addClassroom(
					{
						userId: props.user._id,
						classroomId,
					},
					props.user.token
				)
			)
				.then(() => setLoading(false))
				.catch(() => setLoading(false));
		}
	};

	useEffect(() => {
		const newUser = props.users.find(u => u._id === props.user._id);
		setUser(newUser);
	}, [props.users, props.user._id]);

	return (
		<Grid container spacing={3}>
			<Grid item md={12}>
				{props.isGuide && (
					<Box mb={3}>
						<Typography variant="h4" gutterBottom>
							{'Vyberte si vaše třídy'}
						</Typography>
					</Box>
				)}
				<Paper>
					<Box p={3}>
						<List dense>
							{props.classrooms.map((classroom, key) => {
								const hasClassroom = teacherSettings?.classrooms
									? teacherSettings.classrooms.findIndex(c => c === classroom._id) > -1
									: false;
								return (
									<ListItem key={classroom._id} className="classroom-select" disablePadding>
										<ListItemButton
											disabled={loading !== false}
											onClick={() => toggleClassroom(classroom._id)}
											selected={hasClassroom}
										>
											<ListItemIcon>
												<Checkbox edge="start" checked={hasClassroom} />
											</ListItemIcon>
											<ListItemText
												primary={
													<Typography variant="h5" id={`add-classroom-${key}`}>
														{classroom.title}
													</Typography>
												}
											/>
											{classroom._id === loading && (
												<ListItemIcon>
													<CircularProgress size={16} />
												</ListItemIcon>
											)}
										</ListItemButton>
									</ListItem>
								);
							})}
						</List>
					</Box>
				</Paper>
			</Grid>
			{props.isGuide && (
				<Grid item md={12}>
					<Box component="span" mr={2}>
						<Button
							variant="outlined"
							startIcon={<ChevronLeftIcon />}
							onClick={() => props.onGuide?.('subjects')}
							disabled={loading === 'save'}
						>{`Výběr předmětu`}</Button>
					</Box>
					<Button
						variant="contained"
						endIcon={loading === 'save' ? <CircularProgress size={16} /> : <NavigateNextIcon />}
						onClick={() => {
							setLoading('save');
							dispatch(Reducers.common.users.actions.setState('active', props.user.token)).then(() => {
								setLoading(false);
							});
						}}
						disabled={
							loading === 'save' ||
							!props.user.teacherSettings?.classrooms ||
							props.user.teacherSettings?.classrooms.length === 0
						}
					>{`Uložit nastavení`}</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	classrooms: state.classrooms,
	users: state.users,
}))(MyClassrooms);
