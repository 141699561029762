import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	typography: {
		fontFamily: 'Montserrat',
	},
	palette: {
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#fbc02d',
		},
		error: {
			main: '#d32f2f',
		},
		success: {
			main: '#689f38',
		},
		background: {
			default: '#f5f5f6',
		},
	},
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: 14,
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 42,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				containedSecondary: {
					'&:hover': {
						backgroundColor: '#fbc02d',
					},
				},
				textSizeSmall: {
					textTransform: 'none',
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				colorDefault: {
					backgroundColor: '#fff',
				},
				root: {
					'.MuiTab-root': {
						opacity: 0.7,
						color: '#fff',
						minHeight: 46,
						'&.Mui-selected': {
							color: '#fff',
							background: '#115293',
							opacity: 1,
						},
					},
					'.MuiTabs-indicator': {
						backgroundColor: '#fbc02d',
					},
				},
			},
		},
	},
});

export default theme;
