import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Types from '@taktik/common/types';
import { connect, useDispatch } from 'react-redux';
import Clear from '@mui/icons-material/Clear';
import theme from '../../../../theme';
import T from '@taktik/components/T';
import MyClassroomsList from '../../MyClassrooms';

interface AddClassroomProps {
	open: boolean;
	user: Types.UserSession;
	onClose: () => void;
	classrooms: Types.ClassroomDocument[];
}

const AddClassroom = (props: AddClassroomProps) => {
	const open = props.open;
	const onClose = props.onClose;
	const dispatch = useDispatch();

	props.classrooms.sort((a, b) => a.grade - b.grade);

	return (
		<Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="xs">
			<div
				style={{
					position: 'absolute',
					right: theme.spacing(1),
					top: theme.spacing(1),
				}}
			>
				<IconButton onClick={() => onClose()} id="close-add-classroom">
					<Clear />
				</IconButton>
			</div>
			<DialogTitle>
				<T id="dialogs.teacher.myClassrooms.title" />
			</DialogTitle>
			<MyClassroomsList />
		</Dialog>
	);
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	subjects: state.subjects,
	classrooms: state.classrooms,
	users: state.users,
}))(AddClassroom);
