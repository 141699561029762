import { connect, useDispatch } from 'react-redux';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

interface DeviceProps {
	user: Types.User;
	setUser: (user: Types.User) => void;
	device: Types.TeacherDevice;
	classrooms: Types.ClassroomDocument[];
	socket: Types.VotiSocket;
	token: string;
}

const DeviceMenu = (props: DeviceProps) => {
	const device = props.device;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// @pusher
	const channel = useChannel('device');
	useEvent(channel, 'exam', (data: Types.PusherDeviceRefresh | undefined) => {
		if (device?.serialNumber && data && data.deviceId === device?._id) {
			dispatch(Reducers.voti.device.actions.connect(props.token, device.serialNumber));
		}
	});

	return (
		<Box mr={1}>
			<Button
				variant="outlined"
				color="inherit"
				onClick={() => navigate('/t/device')}
				disabled={!device}
			>
				{device && 'VOTI USB'}
				{!device && 'Pripojte USB'}
			</Button>
		</Box>
	);
};

export default connect((state: Types.RootState) => ({
	device: state.device,
	classrooms: state.classrooms,
	socket: state.socket,
}))(DeviceMenu);
