import { useEffect } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import Can from '@taktik/components/Can';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import GroupIcon from '@mui/icons-material/Group';
import DomainIcon from '@mui/icons-material/Domain';
import AccountTree from '@mui/icons-material/AccountTree';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import Category from '@mui/icons-material/Category';
import ContactSupport from '@mui/icons-material/ContactSupport';
import Explore from '@mui/icons-material/Explore';
import FormatQuote from '@mui/icons-material/FormatQuote';
import MenuBook from '@mui/icons-material/MenuBook';
import Spellcheck from '@mui/icons-material/Spellcheck';
import UsbOutlined from '@mui/icons-material/UsbOutlined';
import VpnKey from '@mui/icons-material/VpnKey';
import useStyles from '../../../../styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, batch } from 'react-redux';
import T from '@taktik/components/T';
import ListItemsTeacher from './ListItemsTeacher';
import ListItemsStudent from './ListItemsStudent';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import i18next from 'i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface MainListItemsProps {
	classrooms: Types.ClassroomDocument[];
	user: Types.User;
	token: string;
}

const MainListItems = (props: MainListItemsProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const language = i18next.language as Types.Languages;

	// @pusher
	const channel = useChannel('schools');
	useEvent(channel, 'refresh', (data: Types.PusherSchoolRefresh | undefined) => {
		if (data && data.schoolId) {
			dispatch(Reducers.common.schools.actions.get(props.token, {}, { page: 0, pageSize: 50 }));
			dispatch(Reducers.common.classrooms.actions.get(props.token, data.schoolId));
		}
	});

	useEffect(() => {
		if (props.user) {
			batch(() => {
				dispatch(Reducers.common.subjects.actions.get(props.token));
				dispatch(Reducers.common.users.actions.get(props.token, {}, { page: 0, pageSize: 100 }));
				dispatch(Reducers.voti.questionTypes.actions.get(props.token));
				dispatch(Reducers.voti.segments.actions.get(props.token));
				dispatch(Reducers.voti.revisions.actions.get(props.token));
				if (props.user.role === Types.RoleTypesList.admin) {
					dispatch(Reducers.voti.devices.actions.get(props.token));
					dispatch(Reducers.voti.quotes.actions.get(props.token));
				}
			});
		}
	}, [props.token, dispatch, props.user]);

	if (location.pathname.startsWith('/s')) {
		return <ListItemsStudent token={props.token} />;
	}

	if (location.pathname.startsWith('/t')) {
		return <ListItemsTeacher token={props.token} classrooms={props.classrooms} />;
	}

	return (
		<List>
			{/* <ListItem
						button
						onClick={() => navigate('/admin')}
						selected={location.pathname === '/admin'}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<DashboardIcon />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.dashboard" />} />
					</ListItem> */}
			<Can
				project="voti"
				language={language}
				perform="admin:users"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/users')}
						selected={location.pathname === '/admin/users'}
						id="nav-admin-users"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<GroupIcon />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.users" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="admin:schools"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/schools')}
						selected={location.pathname.startsWith('/admin/schools')}
						id="nav-admin-schools"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<DomainIcon />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.schools" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="voti:devices"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/devices')}
						selected={location.pathname === '/admin/devices'}
						id="nav-admin-devices"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<UsbOutlined />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.devices" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="voti:exams"
				yes={() => (
					<>
						<ListItem
							button
							onClick={() => navigate('/admin/exams')}
							id="nav-admin-exams"
							selected={location.pathname.startsWith(`/admin/exams`)}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<ContactSupport />
							</ListItemIcon>
							<ListItemText primary={<T id="navBar.exams" />} />
						</ListItem>
						{location.pathname.startsWith(`/admin/exams`) && (
							<Box mt={1} ml={2}>
								<ListItem
									className={classes.menuItemNested}
									button
									href={`/admin/exams`}
									selected={location.pathname === `/admin/exams`}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/admin/exams`);
									}}
									dense
								>
									<ListItemText
										primary={
											<Typography variant="caption">
												<T id="pages.exams.title" />
											</Typography>
										}
									/>
								</ListItem>
								<ListItem
									className={classes.menuItemNested}
									button
									href={`/admin/exams/exercises`}
									selected={location.pathname === `/admin/exams/exercises`}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/admin/exams/exercises`);
									}}
									dense
								>
									<ListItemText
										primary={
											<Typography variant="caption">
												<T id="pages.exams.exercises" />
											</Typography>
										}
									/>
								</ListItem>
								<ListItem
									className={classes.menuItemNested}
									button
									href={`/admin/exams/questions`}
									selected={location.pathname === `/admin/exams/questions`}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/admin/exams/questions`);
									}}
									dense
									id="nav-admin-questions"
								>
									<ListItemText
										primary={
											<Typography variant="caption">
												<T id="pages.exams.questions" />
											</Typography>
										}
									/>
								</ListItem>
								<ListItem
									className={classes.menuItemNested}
									button
									href={`/admin/exams/question-reports`}
									selected={location.pathname === `/admin/exams/question-reports`}
									onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
										event.preventDefault();
										navigate(`/admin/exams/question-reports`);
									}}
									dense
									id="nav-admin-question-reports"
								>
									<ListItemText
										primary={
											<Typography variant="caption">
												<T id="pages.exams.questionReports" />
											</Typography>
										}
									/>
								</ListItem>
							</Box>
						)}
					</>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="voti:revisions:get"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/revisions')}
						selected={location.pathname.startsWith('/admin/revision')}
						id="nav-admin-revisions"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<Spellcheck />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.revisions" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="voti:questionTypes"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/question-types')}
						selected={location.pathname === '/admin/question-types'}
						id="nav-admin-questionTypes"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<Category />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.questionTypes" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="admin:subjects"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/subjects')}
						selected={location.pathname === '/admin/subjects'}
						id="nav-admin-subjects"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<Explore />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.subjects" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="voti:teachingPlans"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/teaching-plans')}
						selected={location.pathname.startsWith('/admin/teaching-plans')}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<AccountTree />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.teachingPlans" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="voti:quotes:get"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/quotes')}
						selected={location.pathname.startsWith('/admin/quote')}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<FormatQuote />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.quotes" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="voti:segments"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/segments')}
						selected={location.pathname.startsWith('/admin/segment')}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<MenuBook />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.segments" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="admin:emailTemplate"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/emails')}
						selected={location.pathname.startsWith('/admin/emails')}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<AlternateEmail />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.emailTemplate" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="voti"
				language={language}
				perform="voti:registrations"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/admin/registrations')}
						selected={location.pathname.startsWith('/admin/registrations')}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<VpnKey />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.registrations" />} />
					</ListItem>
				)}
				no={() => null}
			/>
		</List>
	);
};

export default connect((state: Types.RootState) => ({
	classrooms: state.classrooms,
	user: state.user,
}))(MainListItems);
