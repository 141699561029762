import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { connect, useDispatch } from 'react-redux';
import Types from '@taktik/common/types';
import Reducers from '@taktik/common/reducers';
import TeacherDeviceMenu from './components/TeacherDeviceMenu';
import AdminDevicesMenu from './components/AdminDevicesMenu';
// import StudentHomeworkMenu from './components/StudentHomeworkMenu';
import UserMenu from './components/UserMenu';
import useStyles from '../../../styles';
import { io } from 'socket.io-client';
import ListItemsAdmin from './components/ListItemsAdmin';
import ListItemsTeacher from './components/ListItemsTeacher';
import ListItemsStudent from './components/ListItemsStudent';
// import Notifications from './components/Notifications';
import Image from '../Image';
import logo from '../../../assets/voti.svg';
import theme from '../../../theme';
import T from '@taktik/components/T';
import i18next from 'i18next';

interface NavBarProps {
	children: React.ReactElement;
	user: Types.UserSession;
	users: Types.User[];
	classrooms: Types.ClassroomDocument[];
	socket: Types.VotiSocket;
	page?: string;
	hideMenu?: boolean;
}

const NavBar = (props: NavBarProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = useStyles();
	const [user, setUser] = useState<Types.User>(props.user);
	const { pathname } = useLocation();
	const contentRef = useRef<HTMLDivElement>(null);
	const language = i18next.language as Types.Languages;

	useEffect(() => {
		contentRef.current?.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		if (
			!props.socket &&
			(props.user.role === Types.RoleTypesList.admin ||
				props.user.role === Types.RoleTypesList.employee ||
				props.user.projects?.voti?.[language]?.role === Types.CustomerRoleTypesList.teacher)
		) {
			const socket = io('ws://localhost:5065', { transports: ['websocket'] });
			socket.connect();
			socket.on('disconnect', () => {
				console.log('socked.disconnected');
				dispatch(Reducers.voti.device.actions.disconnect(props.user.token));
				// TODO: set device disconnected
			});
			socket.on('connect', () => {
				console.log('socket.connected');
				socket.emit('set_admin', { is_admin: props.user.role === Types.RoleTypesList.admin });
				socket.emit('cu_reopen', {});
			});
			socket.on('cu_open', response => {
				// TODO: set device connected
				if (response.success && response.serialNumber) {
					dispatch(Reducers.voti.device.actions.connect(props.user.token, response.serialNumber));
				} else {
					dispatch(Reducers.voti.device.actions.disconnect(props.user.token));
				}
			});
			socket.on('read_by_name', response => {
				console.log('read_by_name', response);
				// TODO: is it neccessary?
			});
			socket.on('write_by_name', response => {
				console.log('write_by_name', response);
				// TODO: is it neccessary?
			});
			socket.on('keys_paired', response => {
				console.log('keys_paired', response);
				// TODO: set device results
			});
			dispatch(Reducers.voti.socket.actions.setSocket(socket));
		}
	}, [props.user.role, props.user.token, props.socket, dispatch]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="absolute"
				style={{
					zIndex: theme.zIndex.drawer + 1,
					transition: theme.transitions.create(['width', 'margin'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					boxShadow:
						'0px 2px 20px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 1%)',
				}}
				color="default"
			>
				<Toolbar style={{ minHeight: 52 }}>
					<div className={classes.logo} onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
						<Grid container spacing={1} alignItems="center">
							<Grid item>
								<Image path={logo} isPublic style={{ height: 34 }} />
							</Grid>
							<Grid item>
								<Typography variant="h6" style={{ paddingBottom: 6 }}>
									<T id="types.logoTitle" />
								</Typography>
							</Grid>
						</Grid>
					</div>
					{user.projects?.voti?.[language]?.role === Types.CustomerRoleTypesList.teacher && (
						<TeacherDeviceMenu user={user} setUser={user => setUser(user)} token={props.user.token} />
					)}
					{user.role === Types.RoleTypesList.admin && (
						<AdminDevicesMenu user={user} token={props.user.token} />
					)}
					{/* {user.role === Types.RoleTypesList.student && (
						<StudentHomeworkMenu user={user} token={props.user.token} />
					)} */}
					{/* <Notifications token={props.user.token} /> */}
					<UserMenu />
				</Toolbar>
			</AppBar>
			<Paper
				className={classes.drawerPaper}
				hidden={
					user.projects?.voti?.[language]?.role === Types.CustomerRoleTypesList.student || props.hideMenu
				}
			>
				{(user.role === Types.RoleTypesList.superadmin ||
					user.role === Types.RoleTypesList.admin ||
					user.role === Types.RoleTypesList.employee) && <ListItemsAdmin token={props.user.token} />}
				{user.projects?.voti?.[language]?.role === Types.CustomerRoleTypesList.teacher && (
					<>
						<Divider />
						<ListItemsTeacher
							classrooms={props.classrooms}
							token={props.user.token}
							page={props.page}
							hideMenu={props.hideMenu}
						/>
					</>
				)}
				{user.projects?.voti?.[language]?.role === Types.CustomerRoleTypesList.student && (
					<>
						<Divider />
						<ListItemsStudent token={props.user.token} />
					</>
				)}
			</Paper>
			<main className={classes.contentOpen} ref={contentRef}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="xl" className={classes.container}>
					{props.children}
				</Container>
				<Box mb={40}></Box>
			</main>
		</div>
	);
};

export default connect((state: Types.RootState) => ({
	classrooms: state.classrooms,
	users: state.users,
	socket: state.socket,
	user: state.user,
}))(NavBar);
